import React from 'react';
import { useLocation } from 'react-router-dom';
import Redes from './elements/redes';
import Pagar from '../images/BTN-PAGARremake.png';
import MP from '../images/Mercado-Pago-Logo.png';
import Usuario from '../images/USUARIO.png';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const PagoDeDeudaComponent = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const facturaDataString = queryParams.get('facturaData');
    const facturaData = JSON.parse(decodeURIComponent(facturaDataString));
    console.log(facturaData);

    const handlePayment = async (info, isFirstVencimiento) => {
        try {
            let unitPrice = 0;
            let vto = null; // Inicializamos vto como null

            if (info.ESTADO === 'IMPAGA') {
                if (isFirstVencimiento || info.CTA2_IMP === 0) {
                    unitPrice = parseInt(info.CTA1_IMP);
                    vto = formatDate(parseDate(info.CTA1_VTO)) + ' VTO_1'; // Agregamos el texto VTO_1 al primer vencimiento
                } else {
                    unitPrice = parseInt(info.CTA2_IMP);
                    vto = formatDate(parseDate(info.CTA2_VTO)) + ' VTO_2'; // Agregamos el texto VTO_2 al segundo vencimiento
                }
            } else if (info.ESTADO === 'PARCIAL') {
                unitPrice = parseInt(info.CTA2_IMP);
                vto = formatDate(parseDate(info.CTA2_VTO)) + ' VTO_2'; // Agregamos el texto VTO_2 al segundo vencimiento
            }

            const response = await fetch(
                'https://staging.be.cooperativapopular.com.ar/api/payment',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        items: [
                            {
                                title: info.NROFACT.toString(),
                                quantity: 1,
                                unit_price: unitPrice,
                                nis: facturaData[0].NIS,
                                vto: vto, // Nuevo campo vto
                            },
                        ],
                    }),
                }
            );

            if (!response.ok) {
                throw new Error('Error al procesar el pago');
            }

            const data = await response.json();
            window.location.href = data.init_point;
        } catch (error) {
            console.error('Error al procesar el pago:', error);
        }
    };

    // Función para convertir la fecha de "dd/mm/aaaa" a objeto Date
    function parseDate(dateString) {
        const parts = dateString.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Restamos 1 al mes porque en JavaScript los meses van de 0 a 11
        const year = parseInt(parts[2], 10);
        return new Date(year, month, day);
    }

    // Función para formatear la fecha como "dd/mm/aaaa"
    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 al mes porque en JavaScript los meses van de 0 a 11
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const handleSecondVencimientoClick = (info) => {
        if (info.ESTADO === 'IMPAGA') {
            Swal.fire({
                icon: 'info',
                title: '¡Tienes un saldo anterior pendiente!',
                text: 'Debes pagar primero el saldo anterior antes que este saldo.',
            });
        } else {
            handlePayment(info, false);
        }
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '20px 5px',
    };

    const greenBoxStyle = {
        background: '#E4EFCD',
        padding: '15px 15px',
        width: '100%',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '20px',
    };

    const hrStyle = {
        width: '100%',
        border: '1px solid green',
        margin: '15px 0',
    };

    const divContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '15px',
        marginBottom: '15px',
    };

    const imgStyle = {
        width: '50px',
        height: 'auto',
        position: 'relative',
        top: '23px',
    };

    const usuarioImageStyle = {
        width: '60px',
        marginLeft: 'auto',
        display: 'block',
    };

    const salirLinkStyle = {
        textAlign: 'end',
        display: 'block',
        color: '#5AB507',
        textDecoration: 'none',
        fontSize: '20px',
    };

    const fechaVencimientoStyle = {
        fontSize: '35px',
        margin: '0',
        marginTop: '5px',
        color: '#808080',
    };

    const fechaVencimientoMobileStyle = {
        fontSize: '25px',
        margin: '0',
        marginTop: '5px',
        color: '#808080',
    };

    const salirContainer = {
        position: 'relative',
        top: '40px',
        right: '120px',
        zIndex: '999',
    };

    const responsiveFontSize = {
        fontSize:
            window.innerWidth <= 768
                ? fechaVencimientoMobileStyle.fontSize
                : fechaVencimientoStyle.fontSize,
    };

    const responsiveContainerStyle = {
        ...containerStyle,
        ...responsiveFontSize,
    };

    const responsiveGreenBoxStyle = {
        ...greenBoxStyle,
        ...responsiveFontSize,
    };

    const responsiveDivContainerStyle = {
        ...divContainerStyle,
        flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
        ...responsiveFontSize,
    };

    return (
        <div>
            <div style={{ padding: '60px', marginBottom: '-150px' }}>
                <a href='/PagaTuFactura'>
                    <img
                        src={Usuario}
                        style={usuarioImageStyle}
                        alt='Usuario'
                    />
                </a>
                <a href='/PagaTuFactura' style={salirLinkStyle}>
                    ¡Salir!
                </a>
            </div>

            <div style={salirContainer}></div>
            <div className='container' style={responsiveContainerStyle}>
                <div>
                    <h1
                        style={{
                            color: '#5AB507',
                            marginBottom: '0px',
                            paddingBottom: '10px',
                        }}
                    >
                        Pago de deuda
                    </h1>
                    <h2 style={{ fontSize: '16px', padding: '10px' }}>
                        Número de cuenta: {facturaData[0].NIS}
                    </h2>
                    <span
                        style={{
                            fontSize: '17px',
                            padding: '10px',
                        }}
                    >
                        Hace clic en el icono{' '}
                        <img src={Pagar} style={{ width: '40px' }} />, para
                        pagar tus facturas adeudadas.
                    </span>
                </div>

                <div style={responsiveGreenBoxStyle}>
                    {facturaData.map((info, index) => (
                        <div key={index} style={responsiveGreenBoxStyle}>
                            <h3
                                style={{
                                    color: 'black',
                                    margin: '0',
                                    fontSize: '14px',
                                    marginTop: '10px',
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                }}
                            >
                                FACTURA Nº {info.NROFACT}
                            </h3>
                            <hr style={hrStyle} />
                            {info.CTA1_IMP !== 0 && (
                                <div style={responsiveDivContainerStyle}>
                                    <div>
                                        <p
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                margin: '0',
                                            }}
                                        >
                                            Importe
                                        </p>
                                        <p style={responsiveFontSize}>
                                            ${info.CTA1_IMP}
                                        </p>
                                    </div>
                                    <div>
                                        <p
                                            style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                margin: '0',
                                            }}
                                        >
                                            {info.CTA2_IMP !== 0
                                                ? 'Primer Vencimiento'
                                                : 'Vencimiento'}
                                        </p>
                                        <p style={responsiveFontSize}>
                                            {info.CTA1_VTO || 'Pagar'}
                                        </p>
                                    </div>
                                    {info.ESTADO === 'IMPAGA' && (
                                        <a
                                            href='#'
                                            onClick={() =>
                                                handlePayment(info, true)
                                            }
                                        >
                                            <img
                                                src={Pagar}
                                                alt='pagar'
                                                style={imgStyle}
                                            />
                                        </a>
                                    )}
                                </div>
                            )}
                            {info.CTA2_IMP !== 0 && (
                                <div>
                                    <hr style={hrStyle} />
                                    <div style={responsiveDivContainerStyle}>
                                        <div>
                                            <p
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    margin: '0',
                                                }}
                                            >
                                                Importe
                                            </p>
                                            <p style={responsiveFontSize}>
                                                ${info.CTA2_IMP}
                                            </p>
                                        </div>
                                        <div>
                                            <p
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    margin: '0',
                                                }}
                                            >
                                                Segundo Vencimiento
                                            </p>
                                            <p style={responsiveFontSize}>
                                                {info.CTA2_VTO || 'Pagar'}
                                            </p>
                                        </div>
                                        {info.ESTADO === 'IMPAGA' && (
                                            <a
                                                href='#'
                                                onClick={() =>
                                                    handleSecondVencimientoClick(
                                                        info
                                                    )
                                                }
                                            >
                                                <img
                                                    src={Pagar}
                                                    alt='pagar'
                                                    style={imgStyle}
                                                />
                                            </a>
                                        )}
                                        {info.ESTADO === 'PARCIAL' && (
                                            <a
                                                href='#'
                                                onClick={() =>
                                                    handlePayment(info, false)
                                                }
                                            >
                                                <img
                                                    src={Pagar}
                                                    alt='pagar'
                                                    style={imgStyle}
                                                />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: '100px 0',
                    }}
                >
                    <h1 style={{ color: '#00C853' }}>
                        Medios de pagos habilitados
                    </h1>
                    <a href='#'>
                        <img
                            src={MP}
                            alt='mercadopago'
                            style={{ width: '150px', height: 'auto' }}
                        />
                    </a>
                </div>
            </div>
            <Redes />
        </div>
    );
};

export default PagoDeDeudaComponent;
