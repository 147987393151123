import React, { useState, useEffect } from 'react';
import CardItem from './elements/cards';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import NovedadesTitle from './novedades';

const BloqueTexto = ({ texto }) => {
    const [editorText, setEditorText] = useState('');

    useEffect(() => {
        setEditorText(texto);
    }, [texto]);

    const modules = {
        toolbar: false,
    };

    return <ReactQuill modules={modules} value={editorText} readOnly />;
};

const NovedadesCards = () => {
    const [loading, setLoading] = useState(false);
    const [novedades, setNovedades] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [novedadesPerPage] = useState(3);

    useEffect(() => {
        const cargarNovedades = async () => {
            setLoading(true);
            const response = await axios.get(
                'https://staging.be.cooperativapopular.com.ar/api/novedades'
            );
            setNovedades(response.data);
            setLoading(false);
        };
        cargarNovedades();
    }, [0]);

    const indexOfLastNovedad = currentPage * novedadesPerPage;
    const indexOfFirstNovedad = indexOfLastNovedad - novedadesPerPage;
    const novedadesToShow = novedades.slice(
        indexOfFirstNovedad,
        indexOfLastNovedad
    );

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(novedades.length / novedadesPerPage); i++) {
        pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <button
                key={number}
                className={currentPage === number ? 'active' : null}
                onClick={() => setCurrentPage(number)}
            >
                {number}
            </button>
        );
    });

    const nextPage = () => {
        if (currentPage < pageNumbers.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div id='card-novedades' style={{ marginTop: '150px' }}>
            <NovedadesTitle title='NOVEDADES' />
            <br />
            <br />
            <br />
            <br />
            {loading ? (
                <p>Cargando...</p>
            ) : (
                <>
                    {novedadesToShow.map((item) => (
                        <CardItem
                            key={`novedades#${item.ID}`}
                            title={item.titulo}
                            img={item.imagen}
                            texto={
                                <BloqueTexto texto={JSON.parse(item.cuerpo)} />
                            }
                        />
                    ))}
                    <div
                        id='pagination'
                        style={{ paddingTop: '50px', paddingBottom: '60px' }}
                    >
                        {renderPageNumbers}
                    </div>
                </>
            )}
            <style>
                {`
        .ql-container.ql-snow {
          border: none;
        }
        #pagination {
          display:block;
          margin-top: 20px;
          text-align: center;
          }
          #pagination button {
          background-color: #f5f5f5;
          font-weight: bold;
          color: #000;
          border: 0px solid #ddd;
          padding: 5px 10px;
          margin: 0 5px;
          cursor: pointer;
          }
          #pagination button.active {
          background-color: #00c853;
          border-radius: 5px;
          color: #ffff;
          font-weight: bold;
          }
          #card-novedades {
          margin-top: 30px;
          }
          `}
            </style>
        </div>
    );
};

export default NovedadesCards;
