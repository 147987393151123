import React, { useState, useEffect } from 'react';
import EjemploFact from '../images/FACTURAremake.png';
import Usuario from '../images/USUARIOremake.png';
import Fondo from '../images/fondo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Redes from './elements/redes';
import EstadoDeCuentaComponent from './estadoDeCuentaComponent';
import Swal from 'sweetalert2';
const PagaTuFacturaComponent = () => {
    const [nis, setNis] = useState('');
    const [facturaData, setFacturaData] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const nisParam = urlParams.get('nis');

        if (nisParam) {
            setNis(nisParam);
            handleAutomaticLogin(nisParam);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (nis) {
            handleAutomaticLogin(nis);
        }
    };

    const handleAutomaticLogin = async (nis) => {
        try {
            const response = await fetch(
                `https://staging.be.cooperativapopular.com.ar/api/facturas?nis=${nis}`
            );

            if (response.ok) {
                const data = await response.json();
                console.log(data);

                if (data.length === 0) {
                    // Mostrar SweetAlert
                    Swal.fire({
                        icon: 'error',
                        title: 'Los datos ingresados son incorrectos',
                        text: 'El número de Suministro / NIS es incorrecto, verifica en tu factura e intentalo nuevamente.',
                    });
                    return;
                }

                setFacturaData(data);
                setIsLoggedIn(true);
            } else {
                console.error('Error fetching data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div>
            {!isLoggedIn ? (
                <div>
                    <section style={{ width: '100%' }}>
                        <div
                            className='contenedor-itembuscador'
                            style={{ marginTop: '60px' }}
                        >
                            <h2
                                style={{
                                    textAlign: 'center',
                                    fontSize: '50px',
                                    color: '#4eaa03',
                                    fontWeight: 'bold',
                                }}
                            >
                                Verifica el estado de tu cuenta
                            </h2>
                            <p
                                style={{
                                    textAlign: 'center',
                                    fontSize: '20px',
                                }}
                            >
                                Ademas podes pagar con tarjeta, verificar
                                consumos y reimprimir facturas
                            </p>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '30%',
                                    margin: '0 auto',
                                }}
                            >
                                <form
                                    onSubmit={handleSubmit}
                                    style={{ width: '100%' }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <img
                                            src={Usuario}
                                            alt='usuario'
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                            }}
                                        />

                                        <input
                                            type='text'
                                            placeholder='Número de Suministro / NIS'
                                            value={nis}
                                            onChange={(e) =>
                                                setNis(e.target.value)
                                            }
                                            style={{
                                                width: '60%',
                                                marginLeft: '10px',
                                                flex: '1',
                                                padding: '8px',
                                                border: 'none',
                                                borderBottom:
                                                    '2px solid #4eaa03',
                                                outline: 'none',
                                                marginRight: '30px',
                                                paddingBottom: '0.1px',
                                                marginBottom: '15px',
                                                fontSize: '20px',
                                                textAlign: 'center',
                                            }}
                                        />

                                        <button
                                            type='submit'
                                            style={{
                                                marginTop: '10px',
                                                padding: '5px',
                                                width: '80%',
                                                borderRadius: '10px',
                                                background: '#4eaa03',
                                                color: '#FFFF',
                                                border: 'none',
                                                fontSize: '22px',
                                            }}
                                        >
                                            Ingresar
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1.5fr 1fr',
                                    width: '100%',
                                }}
                            >
                                <img
                                    src={Fondo}
                                    alt='factura'
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        position: 'relative',
                                    }}
                                />
                                <img
                                    className='imgEmploFact'
                                    src={EjemploFact}
                                    alt='usuario'
                                    style={{
                                        width: '130%',
                                        height: '100%',
                                        position: 'relative',
                                        top: '50px',
                                        right: '150px',
                                    }}
                                />
                            </div>

                            <Redes />

                            <style>
                                {`
                                  @media only screen and (max-width: 767px) {
                                    .contenedor-itembuscador {
                                      width: 100%;
                                    }
                                    .imgEmploFact {
                                      width: 120% !important;
                                      height: 90% !important;
                                      position: relative !important;
                                      top: 20px !important;
                                      right: 20px !important;
                                    }
                                    h2 {
                                      font-size: 30px;
                                    }
                                    p {
                                      font-size: 16px;
                                    }
                                    form {
                                      width: 80%;
                                    }
                                    input {
                                      margin-top: 10px;
                                      width: 250% !important;
                                    }
                        
                                    button {
                                      width: 100%;
                                    }
                                    img {
                                      width: 100%;
                                      max-width: 300px;
                                      height: auto;
                                      margin: auto;
                                      object-fit: contain;
                                    }
                                  }
                                `}
                            </style>
                        </div>
                    </section>
                </div>
            ) : (
                <div>
                    <EstadoDeCuentaComponent facturaData={facturaData} />
                </div>
            )}
        </div>
    );
};

export default PagaTuFacturaComponent;
