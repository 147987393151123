import React, { useEffect, useState } from 'react';
import Layout from '../componets/layout';

const urlParams = new URLSearchParams(window.location.search);
const nis = urlParams.get('nis');
const fact = urlParams.get('fact');
const payment_id = urlParams.get('payment_id');

const PagoExitosoPage = () => {
    const [facturaData, setFacturaData] = useState([]);
    useEffect(() => {
        // Obtener todos los valores de la URL
        const urlParams = new URLSearchParams(window.location.search);
        const nis = urlParams.get('nis');
        const title = urlParams.get('fact');
        const collection_id = urlParams.get('collection_id');
        const collection_status = urlParams.get('collection_status');
        const payment_id = urlParams.get('payment_id');
        const status = urlParams.get('status');
        const payment_type = urlParams.get('payment_type');
        const merchant_order_id = urlParams.get('merchant_order_id');
        const preference_id = urlParams.get('preference_id');
        const site_id = urlParams.get('site_id');
        const processing_mode = urlParams.get('processing_mode');

        if (nis) {
            // Realizar la solicitud con el valor de NIS
            fetch(
                `https://staging.be.cooperativapopular.com.ar/api/facturas?nis=${nis}`
            )
                .then((response) => response.json())
                .then((data) => {
                    setFacturaData(data);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }

        /* if (nis && title) {
            // Realizar la solicitud solo si se obtuvieron los valores obligatorios
            fetch('https://staging.be.cooperativapopular.com.ar/api/modificarFactura', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    nis: nis,
                    title: title,
                    back_urls: 'success',
                    collection_id,
                    collection_status,
                    payment_id,
                    status,
                    payment_type,
                    merchant_order_id,
                    preference_id,
                    site_id,
                    processing_mode,
                }),
            })
                .then((response) => response.json())
                .then((data) => {})
                .catch((error) => {
                    console.error('Error al consumir la API:', error);
                });
        } */
    }, []);

    return (
        <div>
            <Layout
                title='Cooperativa Eléctrica de Rivadavia - Pago Exitoso'
                description='Descripción de la página de pago exitoso.'
                keywords='Pago Exitoso, Cooperativa Eléctrica, Rivadavia'
            ></Layout>
            <div
                style={{
                    width: '100%',
                    height: '50vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '40px',
                }}
            >
                <div
                    style={{
                        display: 'block',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <h1
                        style={{
                            fontWeight: 'bold',
                            color: '#00c853',
                            textAlign: 'center',
                        }}
                    >
                        PAGO EXITOSO
                    </h1>
                    <p>
                        Tú pago para la factura <b>nro {fact}</b> de la{' '}
                        <b>cuenta {nis}</b>
                        <br />
                        fue procesado exitosamente, pero puede demorar hasta
                        <br />
                        72hs hábiles en impactar en nuestros sistemas.
                        <br />
                        Tú nro de transaccion de MercadoPago es{' '}
                        <b>{payment_id}</b>
                        <br /> y puedes obtener el comprobante de la operación{' '}
                        <br />
                        desde tu cuenta de MercadoPago.
                        <br />{' '}
                        <b>
                            Si tu servicio ha sido suspendido, por favor,
                            contáctanos. <br /> Hacé clic en el botón de abajo
                            para iniciar el proceso de restablecimiento de tu
                            servicio.
                        </b>
                    </p>
                    <a
                        href='https://wa.me/5492612380051'
                        target='_blank'
                        style={{
                            backgroundColor: '#00c853',
                            borderColor: '#00c853',
                            borderRadius: '5px',
                            color: '#FFFFFF',
                            padding: '10px 20px',
                            textDecoration: 'none',
                            fontWeight: 'bold',
                        }}
                    >
                        Reestablecer mi servicio
                    </a>
                    <br />
                    <br />
                    <a
                        href={`/pagaTuFactura?nis=${nis}`}
                        style={{
                            backgroundColor: '#00c853',
                            borderColor: '#00c853',
                            borderRadius: '5px',
                            color: '#FFFFFF',
                            padding: '10px 20px',
                            textDecoration: 'none',
                            fontWeight: 'bold',
                        }}
                    >
                        Volver a mi cuenta
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PagoExitosoPage;
